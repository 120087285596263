import React from 'react';
import Box from '@mui/system/Box';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import jaime from '../../assets/images/foto.png';
import thumbnail from '../../assets/images/thumbnail.png';

import {
  mainStyle,
  avatarStyle, 
  titleStyle,
  h1Style,
  h2Style,  
  imgBoxStyle, 
  linkStyle,
  imgBoxLinkStyle,
} from './styles';

const URL_YOUTUBE = 'https://www.youtube.com/watch?v=Ckrwp23MaVU';

const Main: React.FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('Página Principal');  

  return (
    <Box sx={mainStyle}>
      <Box sx={titleStyle}>
        <Box component='img' sx={avatarStyle} src={jaime} alt='Jaime'/>
        <Box component='h2' sx={h2Style}>JAIME BENEDETTI EM...</Box>
        <Box component='h1' sx={h1Style}>BRIGANDO COM DEUS</Box>
        
        <Box 
          component='a'
          sx={imgBoxLinkStyle} 
          href={URL_YOUTUBE} 
          target='_blank' 
          rel='noreferrer noopener'
          onClick={()=> gaEventTracker.eventTracker('Visitar Youtube', 'Visitar Youtube')}
        >
          <Box component='img' sx={imgBoxStyle} src={thumbnail} alt='Thumbnail'/>
        </Box>       
        <Box 
          component='a'
          sx={linkStyle} 
          href={URL_YOUTUBE} 
          target='_blank' 
          rel='noreferrer noopener'
          onClick={()=> gaEventTracker.eventTracker('Visitar Youtube', 'Visitar Youtube')}
        >Clique aqui para Assistir!</Box>
        
        {
          /*player ?
            url ? 
              <Box sx={videoBoxStyle}>
                <ReactPlayer
                  style={reactPlayer}
                  url={url}
                  width='100%'
                  height='100%'
                  controls
                  pip
                  playing              
                  //light={<img style={imgStyle} src={thumbnail} alt='Thumbnail' />}            
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload'
                      }
                    }
                  }}
                />           
              </Box>
            :
              <Box component='img' sx={imgBoxStyle} src={thumbnail} alt='Thumbnail' />
          :
            <>
              <Box component='img' sx={imgBoxStyle} src={thumbnail} alt='Thumbnail' onClick={() => setPlayer(true)}/>
              <Box sx={linkStyle} onClick={() => setPlayer(true)}>Clique aqui para Assistir!</Box>
            </> */           
        }
      </Box>
    </Box>    
  );
};

export default Main;
